import React, { useEffect, useState } from "react";
import img from "../../src/res/img/signin.svg";
import { Flowbite, Navbar } from "flowbite-react";
import { Button, Label } from "flowbite-react";
import { facebookClientId, name, serverURL, websiteURL } from "../constants";
import DarkModeToggle from "../components/DarkModeToggle";
import LogoComponent from "../components/LogoComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlineLoading } from "react-icons/ai";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import FacebookLogin from "@greatsumini/react-facebook-login";

const SignIn = () => {
    const storedTheme = sessionStorage.getItem("darkMode");
    const [email, setEmail] = useState("");
    const [processing, setProcessing] = useState(false);
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    };

    const state = {
        jsonData: JSON.parse(decodeURIComponent(getQueryParam("jsonData"))),
        mainTopic: getQueryParam("mainTopic"),
        type: getQueryParam("type"),
        apiUrl: getQueryParam("apiUrl"),
        end: getQueryParam("end"),
        completed: getQueryParam("completed"),
        courseId: getQueryParam("courseId"),
    };

    const handleAutoLoginOrSignup = async () => {
        setIsLoading(true);
        if (!state.mainTopic) {
            return;
        }
        const email = getQueryParam("email");
        const mName = getQueryParam("name");
        const password = "123456789";

        if (!email || !mName) {
            showToast("Missing email or name in URL parameters");
            return;
        }

        const checkAccountURL = serverURL + "/api/check-account";
        const signinURL = serverURL + "/api/signin";
        const signupURL = serverURL + "/api/signup";

        try {
            const checkResponse = await axios.post(checkAccountURL, { email });

            if (checkResponse.data.success) {
                const userData = checkResponse.data.userData;

                const loginResponse = await axios.post(signinURL, {
                    email: userData.email,
                    password: userData.password,
                });

                if (loginResponse.data.success) {
                    sessionStorage.setItem("email", userData.email);
                    sessionStorage.setItem("mName", userData.mName);
                    sessionStorage.setItem("auth", true);
                    sessionStorage.setItem("uid", userData._id);
                    sessionStorage.setItem("type", userData.type);
                    showToast("Login successful!");
                    handleCourse(state);
                    setIsLoading(false);
                    return;
                }
            } else {
                const signupResponse = await axios.post(signupURL, {
                    email,
                    mName,
                    password,
                    type: "free",
                });

                if (signupResponse.data.success) {
                    sessionStorage.setItem("email", email);
                    sessionStorage.setItem("mName", mName);
                    sessionStorage.setItem("auth", true);
                    sessionStorage.setItem("uid", signupResponse.data.userId);
                    sessionStorage.setItem("type", "free");
                    showToast("Signup successful!");
                    setIsLoading(false);
                    handleCourse(state);
                }
            }
        } catch (error) {
            setIsLoading(false);
            showToast("Failed to process login/signup. Please try again.");
            console.error(error);
        }
        setIsLoading(false);
    };

    const handleCourse = async (state) => {
        const { jsonData, mainTopic, type, courseId, completed, end } = state;
        if (!courseId) {
            navigate("/topics", { state: state });
            return;
        }
        let jsonDataObj;
        if (!jsonData) {
            const getURL = `${serverURL}/api/getCourseById?id=${courseId}`;
            try {
                const response = await axios.get(getURL);
                jsonDataObj = JSON.parse(response.data.content);
                console.log(jsonDataObj);
            } catch (error) {
                console.error("Error fetching course data:", error);
                return;
            }
        } else {
            jsonDataObj = JSON.parse(jsonData);
        }
    
        sessionStorage.setItem("courseId", courseId);
        sessionStorage.setItem("first", completed);
        sessionStorage.setItem("jsonData", JSON.stringify(jsonDataObj));
    
        let ending = "";
        if (completed !== "false") {
            ending = end;
        }
    
        navigate("/course", {
            state: {
                jsonData: jsonDataObj,
                mainTopic: mainTopic.toUpperCase(),
                type: type.toLowerCase(),
                courseId: courseId,
                end: ending,
            },
        });
    };    

    useEffect(() => {
        showToast(state);
        const email = sessionStorage.getItem("email");
        const auth = sessionStorage.getItem("auth");
        if (email && auth && state.mainTopic) {
            handleCourse(state);
        } else {
            if (state.mainTopic) {
                handleAutoLoginOrSignup();
            } else {
                if (sessionStorage.getItem("auth")) {
                    redirectHome();
                }
            }
        }
    }, []);

    function redirectSignUp() {
        navigate("/signup");
    }

    function redirectForgot() {
        navigate("/forgot");
    }

    function redirectHome() {
        navigate("/home");
    }

    const showToast = async (msg) => {
        setProcessing(false);
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleSignin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            showToast("Please fill in all required fields");
            return;
        }
        const postURL = serverURL + "/api/signin";
        try {
            setProcessing(true);
            const response = await axios.post(postURL, { email, password });
            if (response.data.success) {
                showToast(response.data.message);
                sessionStorage.setItem("email", response.data.userData.email);
                sessionStorage.setItem("mName", response.data.userData.mName);
                sessionStorage.setItem("auth", true);
                sessionStorage.setItem("uid", response.data.userData._id);
                sessionStorage.setItem("type", response.data.userData.type);
                redirectHome();
            } else {
                showToast(response.data.message);
            }
        } catch (error) {
            showToast("Internal Server Error");
        }
    };

    return (
        <Flowbite>
            <div className="flex h-screen dark:bg-black no-scrollbar">
                {isLoading && (
                    <div
                        className="absolute px-3 h-full w-full flex justify-center items-center"
                        style={{
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderRadius: "5px",
                        }}
                    >
                        <div role="status">
                            <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                ></path>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                ></path>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                <div className="flex-1 overflow-y-auto no-scrollbar">
                    <Navbar fluid className="p-8 dark:bg-black">
                        <Navbar.Brand href={websiteURL} className="ml-1">
                            <LogoComponent isDarkMode={storedTheme} />
                            <span className="self-center whitespace-nowrap text-2xl font-black dark:text-white ">
                                {name}
                            </span>
                        </Navbar.Brand>
                        <DarkModeToggle />
                    </Navbar>

                    <form
                        onSubmit={handleSignin}
                        className="max-w-sm m-auto py-9 no-scrollbar"
                    >
                        <h1 className="text-center font-black text-5xl text-black dark:text-white">
                            SignIn
                        </h1>
                        <p className="text-center font-normal text-black py-4 dark:text-white">
                            Enter email & password to continue
                        </p>

                        <div className="py-10">
                            <div className="mb-6">
                                <div className="mb-2 block">
                                    <Label
                                        className="font-bold text-black dark:text-white"
                                        htmlFor="email1"
                                        value="Email"
                                    />
                                </div>
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white"
                                    id="email1"
                                    type="email"
                                />
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label
                                        className="font-bold text-black dark:text-white"
                                        htmlFor="password1"
                                        value="Password"
                                    />
                                </div>
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="focus:ring-black focus:border-black border border-black font-normal bg-white rounded-none block w-full dark:bg-black dark:border-white dark:text-white"
                                    id="password1"
                                    type="password"
                                />
                            </div>
                            <div className="flex items-center mb-10">
                                <p
                                    onClick={redirectForgot}
                                    className="text-center font-normal text-black underline dark:text-white"
                                >
                                    Forgot Password ?
                                </p>
                            </div>
                            <Button
                                isProcessing={processing}
                                processingSpinner={
                                    <AiOutlineLoading className="h-6 w-6 animate-spin" />
                                }
                                className="items-center justify-center text-center dark:bg-white dark:text-black bg-black text-white font-bold rounded-none w-full enabled:hover:bg-black enabled:focus:bg-black enabled:focus:ring-transparent dark:enabled:hover:bg-white dark:enabled:focus:bg-white dark:enabled:focus:ring-transparent"
                                type="submit"
                            >
                                Submit
                            </Button>
                            <p
                                onClick={redirectSignUp}
                                className="text-center font-normal text-black underline py-4  dark:text-white"
                            >
                                Don't have an account ? SignUp
                            </p>

                            {/* <GoogleLogin
                                theme='outline'
                                type='standard'
                                style={{
                                    display: 'none'
                                }}
                                width={400}
                                onSuccess={async (credentialResponse) => {
                                    const decoded = jwtDecode(credentialResponse.credential);
                                    let email = decoded.email;
                                    let name = decoded.name;
                                    const postURL = serverURL + '/api/social';
                                    try {
                                        setProcessing(true);
                                        const response = await axios.post(postURL, { email, name });
                                        if (response.data.success) {
                                            showToast(response.data.message);
                                            sessionStorage.setItem('email', decoded.email);
                                            sessionStorage.setItem('mName', decoded.name);
                                            sessionStorage.setItem('auth', true);
                                            sessionStorage.setItem('uid', response.data.userData._id);
                                            sessionStorage.setItem('type', response.data.userData.type);
                                            redirectHome();
                                        } else {
                                            showToast(response.data.message);
                                        }
                                    } catch (error) {
                                        showToast('Internal Server Error');
                                    }

                                }}
                                onError={() => {
                                    showToast('Internal Server Error');
                                }}
                            /> */}

                            <FacebookLogin
                                appId={facebookClientId}
                                style={{
                                    display: "none",
                                    backgroundColor: "#4267b2",
                                    color: "#fff",
                                    fontSize: "15px",
                                    padding: "8px 24px",
                                    width: "104%",
                                    border: "none",
                                    marginTop: "16px",
                                    borderRadius: "0px",
                                }}
                                onFail={(error) => {
                                    showToast("Internal Server Error");
                                }}
                                onProfileSuccess={async (response) => {
                                    let email = response.email;
                                    let name = response.name;
                                    const postURL = serverURL + "/api/social";
                                    try {
                                        setProcessing(true);
                                        const response = await axios.post(postURL, { email, name });
                                        if (response.data.success) {
                                            showToast(response.data.message);
                                            sessionStorage.setItem("email", response.email);
                                            sessionStorage.setItem("mName", response.name);
                                            sessionStorage.setItem("auth", true);
                                            sessionStorage.setItem("uid", response.data.userData._id);
                                            sessionStorage.setItem(
                                                "type",
                                                response.data.userData.type
                                            );
                                            redirectHome();
                                        } else {
                                            showToast(response.data.message);
                                        }
                                    } catch (error) {
                                        showToast("Internal Server Error");
                                    }
                                }}
                            />
                        </div>
                    </form>
                </div>

                <div className="flex-1 hidden lg:flex items-center justify-center bg-gray-50 dark:bg-white">
                    <img
                        src={img}
                        className="h-full bg-cover bg-center p-9"
                        alt="Background"
                    />
                </div>
            </div>
        </Flowbite>
    );
};

export default SignIn;
